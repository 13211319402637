import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {Routes,RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {  AppGlobals} from './app.global';
const routes : Routes = [
  { path: '', redirectTo: '/login',pathMatch:'full' },
  {path : 'login', loadChildren : './login/login.module#LoginModule'},
  {path : 'track', loadChildren : './track/track.module#TrackModule'}
]

@NgModule({
  declarations: [
    AppComponent    
  ],
  imports: [
    BrowserModule, RouterModule.forRoot(routes),BrowserAnimationsModule,
    HttpClientModule,HttpModule,NgHttpLoaderModule,SimpleNotificationsModule.forRoot()
  ],
  providers: [{provide: LocationStrategy,useClass:HashLocationStrategy},AppGlobals],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'solvup-customer';
  public spinkit = Spinkit;
  public options = {
    position: ["top", "right"],
    timeOut: 3000,
   }

}
